import './SideNavSection.scss';

import React, { ReactElement } from 'react';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const SideNavSection = (): ReactElement => {
  const { t } = useTranslation();
  // TODO 본인의 번호를 닉네임으로 변경하세요
  const nickName = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
  ];

  return (
    <div id="SideNavSection">
      <Nav className="flex-column">
        <NavLink to="/main/board" className="nav-link">
          {t('sample.label.FreetalkingBoardSideBar.basic')}
        </NavLink>
        {nickName.map((value, index) => {
          return (
            <NavLink to={'/main/board-' + (index + 1)} className="nav-link" key={value}>
              {t('sample.label.FreetalkingBoardSideBar.number', { number: nickName[index] })}
            </NavLink>
          );
        })}
        <NavLink key="event" to="/main/event" className="nav-link">
          {t('sample.label.FreetalkingBoardSideBar.familyEvent')}
        </NavLink>
      </Nav>
    </div>
  );
};

export default SideNavSection;
