import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginForm.scss';

import React, { ReactElement, useContext, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// import { Toast } from '../../../../service/common/model/Toast';
import { AuthContext } from '../../../../App';
import { CommonActionType } from '../../../../reducer/actions';
// import LoginService from '../../../../service/admin/LoginService';
import CookieService from '../../../../service/CookieService';
import { FormFieldCode } from '../../../../service/member/login/model/CommonConstants';
// import LoginRequest from '../../../../service/admin/model/LoginRequest';
import SessionService from '../../../../service/SessionService';

const LoginForm: React.FC = (): ReactElement => {
  // const toast: Toast = { toastMessage: '', showToast: true };
  const cookieService = new CookieService();
  const sessionService = new SessionService();
  // const loginService = new LoginService();

  const [formState, setFormState] = useState({
    formFieldsValue: {
      email: cookieService.getCookie('emailAddress') || '',
      password: '',
      checkSaveId: cookieService.getCookie('emailAddress').length > 0,
    },
    formFieldsErrorMessage: {
      email: '',
      password: '',
    },
    formFieldsValidity: {
      email: cookieService.getCookie('emailAddress').length > 0,
      password: false,
    },
  });

  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);

  const setLoading = (isLoading: boolean) => {
    dispatch({ type: CommonActionType.LOADING, isLoading: isLoading });
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const isCheckSaveId = name === 'checkSaveId';
    const fieldsValue = formState.formFieldsValue;

    if (isCheckSaveId) {
      fieldsValue[name] = target.checked;
    } else {
      fieldsValue[name] = value;
    }

    setFormState({
      ...formState,
      formFieldsValue: fieldsValue,
    });

    handleValidation(name, value);
  };

  const handleValidation = (name, value) => {
    const fieldsErrorMessage = formState.formFieldsErrorMessage;
    const fieldsValidity = formState.formFieldsValidity;
    const isEmail = name === FormFieldCode.EMAIL;
    const isPassword = name === FormFieldCode.PASSWORD;
    const emailTest = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

    if (isEmail) {
      fieldsValidity[name] = value.length > 0;
      fieldsErrorMessage[name] = fieldsValidity[name] ? '' : t('admin.message.LoginForm.emailAddress.required');
      if (fieldsValidity[name]) {
        fieldsValidity[name] = emailTest.test(value);
        fieldsErrorMessage[name] = fieldsValidity[name] ? '' : t('admin.message.LoginForm.emailAddress.common');
      }
    } else if (isPassword) {
      fieldsValidity[name] = value.length >= 3;
      fieldsErrorMessage[name] = fieldsValidity[name] ? '' : t('admin.message.LoginForm.password.required');
    }

    setFormState({
      ...formState,
      formFieldsErrorMessage: fieldsErrorMessage,
      formFieldsValidity: fieldsValidity,
    });
  };

  //const handleSubmit = async event => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const { formFieldsValue, formFieldsValidity } = formState;

    if (Object.values(formFieldsValidity).every(Boolean)) {
      // const loginRequest: LoginRequest = {
      //   emailAddress: formFieldsValue.email,
      //   password: formFieldsValue.password,
      // };

      setLoading(true);
      // await loginService.requestLogin(loginRequest).then(submitStatus:SubmitStatus => {
      //   if (submitStatus.successOrNot === 'Y' && submitStatus.statusCode === StatusCode.SUCCESS) {
      //     sessionService.deleteSessionInfo();
      //     sessionService.setSessionId(submitStatus.data.sessionId);
      //     sessionService.setSessionInfo(submitStatus.data);

      //     formFieldsValue.checkSaveId
      //       ? cookieService.setCookie('emailAddress', loginRequest.emailAddress)
      //       : cookieService.deleteCookie('emailAddress');

      //     window.location.assign('/');
      //   } else {
      //     toast.toastMessage = 'fail';
      //     dispatch({ type: CommonActionType.SHOW_TOAST, toast: toast });
      //   }
      // }).finally(() => {
      //   setLoading(false);
      // });
      sessionService.setSessionInfo({
        sessionId: 'testSessionId',
        memberId: 1,
        memberName: '테스터',
        emailAddress: 'test@lgcns.com',
        companyCode: 'companyCode',
        companyName: 'AIC',
        languageCode: 'ko',
        roleType: 'systemAdmin',
      });
      setLoading(false);
      window.location.assign('/');
    } else {
      handleValidation(FormFieldCode.EMAIL, formFieldsValue.email);
      handleValidation(FormFieldCode.PASSWORD, formFieldsValue.password);
    }
  };

  return (
    <div id="loginForm">
      <Form.Group id="formInput">
        <Row className="form-row">
          <Form.Group id="formEmail">
            <Form.Control
              id="formControlEmail"
              name="email"
              data-testid="email"
              required
              type="text"
              placeholder={t('admin.label.LoginForm.emailIdPlaceholder')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit(e);
                }
              }}
              onChange={(e) => {
                handleChange(e);
              }}
              value={formState.formFieldsValue.email}
              isInvalid={!formState.formFieldsValidity.email}
              autoFocus
            />
            {formState.formFieldsErrorMessage.email && (
              <Row className="form-row feedback" id="formFeedbackEmail" data-testid="emailIdFeedback">
                {formState.formFieldsErrorMessage.email}
              </Row>
            )}
          </Form.Group>
        </Row>
        <Row className="form-row">
          <Form.Group id="formPassword">
            <Form.Control
              id="formControlPassword"
              name="password"
              data-testid="password"
              required
              type="password"
              placeholder={t('admin.label.LoginForm.passwordPlaceholder')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit(e);
                }
              }}
              onChange={(e) => {
                handleChange(e);
              }}
              isInvalid={!formState.formFieldsValidity.password}
            />
            {formState.formFieldsErrorMessage.password && (
              <Row className="form-row feedback" id="formFeedbackPassword" data-testid="passwordFeedback">
                {formState.formFieldsErrorMessage.password}
              </Row>
            )}
          </Form.Group>
        </Row>
        <Row className="form-row">
          <Button
            id="formSubmitButton"
            name="loginSubmitButton"
            className="formSubmitButton"
            onClick={handleSubmit}
            data-testid="loginSubmitButton"
            type="submit"
            disabled={!formState.formFieldsValidity.email || !formState.formFieldsValidity.password}
          >
            {t('admin.label.LoginForm.login')}
          </Button>
        </Row>

        <Row className="form-row">
          <Form.Check
            id="formSaveIdCheck"
            className="formSaveIdCheck"
            name="checkSaveId"
            data-testid="checkSaveId"
            type="checkbox"
            label={t('admin.label.LoginForm.saveId')}
            onChange={(e) => {
              handleChange(e);
            }}
            defaultChecked={formState.formFieldsValue.checkSaveId}
          />
        </Row>
      </Form.Group>
    </div>
  );
};

export default LoginForm;
